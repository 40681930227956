import React, {useContext, useEffect, useState} from 'react';
import ContainersList from "../components/ContainersList";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";



function Containers () {
    const {store} = useContext(Context);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(store.limit)
    const [containers, setContainers] = useState(store.getContainers());
    const [pageContainers, setPagecontainers] = useState([])
    const [totalPage, setTotalpage] = useState(Math.ceil(containers.length / store.limit))
    const getListContainers = (start, end) => {
        let array = [];
        for(start; start < end; start++){
            if(typeof containers[start] !== 'undefined'){
                array.push(containers[start])
            }
            if(start > containers.length){
                return array;
            }

        }
        return array;
    }
    const [firstFetchContainers, isPageLoading] = useFetching(async () => {
        if(containers.length > 0){
            let start = (page - 1) * store.limit
            let end = page * store.limit
            setPagecontainers(getListContainers(start,end))

        } else {
            const response = await $api.get(`/containers?l=${store.limit}`)
            if (response.status == 200) {
                setPagecontainers(response.data.containers);
            }
        }
    })
    const [fetchContainers, isContainersLoading] = useFetching(async (limit, page) => {
        const response = await $api.get('/containers')
        if(response.status == 200) {
            store.setConteiners(response.data.containers);
            setContainers(response.data.containers)
        }
    })

    const changePage = (p) => {

        setPage(p)

    }
    const blockedContainer = async (e) => {
        if(window.confirm('Вы хотите заблокировать контейнер?')) {
            let id = e.target.closest('div').dataset.id
            await $api.get(`/containers/block/${id}`)
            fetchContainers();
        }
    }

    const unBlockedContainer = async (e) => {
        if(window.confirm('Контейнер будет разблокирован, продолжить?')) {
            let id = e.target.closest('div').dataset.id
            await $api.get(`/containers/unblock/${id}`)
            fetchContainers();
        }
    }
    const downloadPhoto = async (e) => {
        let id = e.target.dataset.id;
        let name = e.target.dataset.number + "_" + e.target.dataset.start + '.zip'
        console.log(e.target);
        await $api.get(`/containers/downloadPhoto?id=${id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }
    const handlePaginatorCount = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    useEffect(() => {
        firstFetchContainers();
        fetchContainers();
        const interval = setInterval(function(){
            fetchContainers()
        }
            , 300000);
        return () => clearInterval(interval);
    }, [])
    useEffect(() => {
        console.log(limit)
        let start = (page - 1) * limit
        let end = page * limit
        console.log(start);
        console.log(end);
        setPagecontainers(getListContainers(start,end))
        console.log(pageContainers)
        setTotalpage(Math.ceil(containers.length / limit))
    }, [page, containers, limit])
    const handleFilter = (fl) => {
        let _containers = store.containers

        if(fl.number !== ''){
            _containers = _containers.filter(container => {
                return  container.number.toLowerCase().includes(fl.number.toLowerCase())
            })
        }
        if(fl.reload_number !== ''){
            
            _containers = _containers.filter(container => {

                if(typeof container.relod_number !== 'undefined' && container.relod_number !== null) {
                    return container.relod_number.toLowerCase().includes(fl.reload_number.toLowerCase())
                }
            })
        }

        if(fl.content_artikul !== ''){
            _containers = _containers.filter(container => {
                if(typeof container.content_artikul !== 'undefined' && container.content_artikul !== null) {
                    return container.content_artikul.toLowerCase().includes(fl.content_artikul.toLowerCase())
                }
            })
        }

        if(fl.type !== ''){
            _containers = _containers.filter(container => {
                if (container.type == fl.type) return container;
            })
        }
        if(fl.state !== ''){
            _containers = _containers.filter(container => {
                if (container.state === fl.state) return container;
            })
        }
        if(fl.content !== ''){
            _containers = _containers.filter(container => {
                if (container.content  === fl.content ) return container;
            })
        }
         setPage(1)
         setContainers(_containers)

    }

    if(isPageLoading && containers.length === 0) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">
                <ContainersList totalPage={totalPage} countContainers = {containers.length} containersList={pageContainers} blockedContainer={blockedContainer}
                                limit={limit} handlePaginatorCount={handlePaginatorCount} isContainersLoading={isContainersLoading} unBlockedContainer={unBlockedContainer} downloadPhoto={downloadPhoto} page={page} handleFilter={handleFilter} changePage={changePage}/>
            </div>
        );
    }
}

export default Containers;
