import React from 'react';
import {useNavigate} from 'react-router-dom';
import Datelocale from "./UI/Datelocale";
import {useIntl} from "react-intl";
import Icon from "@reacticons/bootstrap-icons";
import Doclist from "./UI/Doclist";
import Badge from "react-bootstrap/Badge";

const ContainerItem = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const state = {A:intl.messages.intact,U:intl.messages.damaged,UA:intl.messages.refurbished,WR:intl.messages.refused_to_refurbish}
    const content = {E:intl.messages.empty,F:intl.messages.fullc}
 /*   const clickRow = (e) => {

        let link = "/release/" + e.target.closest("tr").dataset.link
        navigate(link)
    }
    */

    const clickRepair = () => {
        navigate('/repairs');
    }

    const setDocs = () => {
        let doc = [];
       if(props.container.patch_front){
            doc.push(props.container.patch_front)
        }
        if(props.container.patch_left){
            doc.push(props.container.patch_left)
        }
        if(props.container.patch_right){
            doc.push(props.container.patch_right)
        }
        if(props.container.patch_rear){
            doc.push(props.container.patch_rear)
        }

        if(props.container.photo != null && props.container.photo.length > 0 ){
            for(let r in props.container.photo){
                doc.push(props.container.photo[r])
            }
        }
        return doc;

    }



    return (
        <><tr >
            <td>{props.num}</td>

            <td>{props.container.number}</td>
            {props.reload_number?<td>{props.container.relod_number}</td>:null}
            <td>{props.container.type_name}</td>
            <td>{props.container.size}</td>
            <td>{props.container.tonnage}</td>
            <td>{state[props.container.state]}
                {props.container.state === 'U' ?
                    <>
                 <span > {!props.container.repair ?
                   null :
                     <button className="btn btn-warning" onClick={clickRepair} title={intl.messages.repairs_title} style={{marginLeft: '15px'}} type="button">
                         <Icon name={"calculator"}></Icon>
                     </button>}
                 </span>
                 </>
                    : null}

            </td>
            <td>{content[props.container.content]}</td>
            {props.content_name ? <td>{props.container.content_name}</td>:null}
            {props.content_artikul  ? <td>{props.container.content_artikul}</td>:null}
            {props.content_count ? <td>{props.container.content_count}</td> : null}
            
            <td><Datelocale date={props.container.date_start} /></td>
            <td>{props.container.days}</td>
            <td><Doclist photo={setDocs()}  /></td>
            <td><Badge bg="info" className="pointer" data-id={props.container.id} data-number={props.container.number} data-start={props.container.date_start} onClick={props.downloadPhoto} >{intl.messages.download}</Badge></td>
            <td>{props.container.is_blocked?<Icon name="lock" title={intl.messages.unblocked} data-id={props.container.id} onClick={props.unBlockedContainer} />:<Icon name="unlock" title={intl.messages.blocked} data-id={props.container.id} onClick={props.blockedContainer} />}</td>

        </tr>
        </>
    );
};

export default ContainerItem;
